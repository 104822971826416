<template>
	<!-- nav -->
	<nav class="nav">
		<div class="nav__wrap">
			<a>
			</a>
			<p class="nav__item">
				<span class="nav__text">
					{{t('10706')}}
				</span>
			</p>
			<a class="nav__btn nav__btn--prev" @click="goBack">
				<i class="icon icon-close">{{t('10030')}}</i>
			</a>
		</div>
	</nav>
	<div class="sb_result_container">
		<img v-if="result === '1'" src="@/assets/images/Sbdeajeon_suc.png" alt="H_SALAD">
		<img v-else src="@/assets/images/Sbdeajeon_fail.png" alt="H_SALAD">
		<p v-if="result === '1'">ID : {{userData.mem_id === '' ? "Email":userData.mem_id }}에 <br>숙박대전 할인쿠폰이 <span style="color:#ff5a5a">발급완료</span> 되었습니다 :)</p><!-- 재번역체크 -->
		<p v-else style="color:#ff5a5a">{{t('10711')}}</p>
		
		<a v-if="result === '1'" @click="goHome">{{t('10712')}}</a>
		<a v-else @click="goHome">돌아가기</a><!-- 재번역체크 -->
	</div>
	<!-- // nav -->
	<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
	<TermsAgree v-if="openTerm === 'agree'" :handleClose="handleCloseTerm" />
	<TermsThird v-if="openTerm === 'third'" :handleClose="handleCloseTerm" />
</template>
<style>
	.wrap{padding-bottom:0px;height:100%;}
	body{height:100%;}
	html{height:100%;}
	#app{height:100%;}
</style>
<script>
import { useStore } from "vuex"
import { computed, ref } from "vue";
import { useRoute, useRouter } from 'vue-router';
import dayjs from 'dayjs'
import "dayjs/locale/ko";
import ErrorAlert from "@/components/layers/Message_alert";
import TermsThird from "@/components/layers/Terms_third";
import TermsAgree from "@/components/layers/Terms_agree";
import { useI18n } from 'vue-i18n' 
import i18n from "@/i18n"; 

dayjs.locale("ko");

export default {
	components: {
		ErrorAlert,
		TermsAgree,
		TermsThird
	},
	setup: function() {
		const store = useStore();
		const selectedMenuId = ref("01");
		const router = useRouter();
		const route = useRoute();
		const locaition1 = ref("");
		const locaition2 = ref("");
		const openErrorAlert = ref(false)
		const checkedList = ref([]);
		const result = route.params.id;
		const openTerm = ref(null);
		const isLogin = computed(() => Object.keys(userData.value).length !== 0);
		const userData = computed(() => store.state.userData);
		const { t }= useI18n() 


		return {
			selectedMenuId,
			locaition1,
			locaition2,
			openTerm,
			result,
			checkedList,
			openErrorAlert,
			t,  //번역필수 모듈
		  i18n, 
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
			handleOpenTerm: (type) => {
				openTerm.value = type;
			},
			handleCloseTerm: () => {
				openTerm.value = null;
			},
			userData,
			isLogin,
			goBack (){
				this.$router.back()
			},
			goHome (){
				router.push('/')
			}
		}
	}
}
</script>